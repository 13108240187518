import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './auth/utils/PrivateRoute';
import { AuthProvider } from './auth/context/AuthContext';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// import K8sResourcesPage from './pages/K8sResourcesPage';
// Authentication
import GoogleLogin from './auth/pages/GoogleLogin';
import StageLogin from './auth/pages/StageLogin';
import Unauthorized from './auth/pages/UnauthorizedPage';

// Pages
import HomePage from './pages/HomePage';
import SignInPage from './pages/SignInPage';
import AccountsPage from './pages/AccountsPage';
import LoansPage from './pages/LoansPage';
import ServicesPage from './pages/ServicesPage';
import ResourcesPage from './pages/ResourcesPage';
import ContactPage from './pages/ContactPage';
import LegalPage from './pages/LegalPage';
import SecurityCenterPage from './pages/SecurityCenterPage';
import EducationCenterPage from './pages/EducationCenterPage';
import RatesPage from './pages/RatesPage';
import FormSubmittedPage from './components/forms/loans/FormSubmittedPage';
import MaintenancePage from './pages/MaintenancePage';

// Forms
import FormsPage from './pages/FormsPage';

import withRoot from './modules/withRoot'; // Theme

function App() {
  // Palette = {
  //     // coolors.co for palette picking
  //     raisin_black: '#28282A',
  //     oxford_blue: '#002350',
  //     cerulean_blue: '#056E8B',
  //     poppy_red: '#DB3A34',
  //     sunglow_yellow: '#FFC857',
  // };

  const darkTheme = createTheme({
    // palette: {
    //     mode: 'dark',
    //     background: {
    //         container: '#212121',
    //         modal: '#212121',
    //         box: '#212121',
    //     },
    // },
    palette: {
      mode: 'light',
      // background: {
      //     container: '#212121',
      //     modal: '#212121',
      //     box: '#212121',
      // },
    },
  });

  return (
    <Router>
      {/* <ThemeProvider theme={darkTheme}> */}
      {/* <CssBaseline enableColorScheme /> */}
      {/* <AuthProvider> */}
      <Routes>
        {/* Public Routes */}
        <Route path="/health" element={<h3>The website is healthy</h3>} />
        {/* <Route path="/login" element={<GoogleLogin />} />
          <Route path="/login/callback" element={<StageLogin />} />
          <Route path="/unauthorized" element={<Unauthorized />} /> */}
        <Route path="/applications/completed" element={<FormSubmittedPage />} />
        {/* Private Routes */}
        {/* <Route element={<PrivateRoutes />}> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/rates" element={<RatesPage />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/loans" element={<LoansPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/security" element={<SecurityCenterPage />} />
        <Route path="/education" element={<EducationCenterPage />} />
        <Route path="/applications" element={<FormsPage />} />
        <Route path="/signin" element={<SignInPage />} />

        {/* </Route> */}
        {/* Catch All */}
        <Route path="*" element={<HomePage />} />
      </Routes>
      {/* </AuthProvider> */}
      {/* </ThemeProvider> */}
    </Router>
  );
}

export default withRoot(App);
