import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

// --Tables--
// Shares
import SharesTable from '../tables/SharesTable';
// Certificates
import CertificatesTable from '../tables/CertificatesTable';
// Loans
import AutoLoansTable from '../tables/AutoLoansTable';
import RvBoatLoansTable from '../tables/RvBoatLoansTable';
import ConsumerLoansTable from '../tables/ConsumerLoansTable';
import UnsecuredLoansTable from '../tables/UnsecuredLoansTable';
// Mortgages
import FixedFirstMortgageTable from '../tables/FixedFirstMortgageTable';
import FixedSecondMortgageTable from '../tables/FixedSecondMortgageTable';
import HelocTable from '../tables/HelocTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      // stretch to fill the height of the parent container
      style={{ width: '100%', marginLeft: 3, marginRight: 3 }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function RatesTabs() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  // const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const tabsOrientation = useMediaQuery(theme.breakpoints.down('md'))
    ? 'horizontal'
    : 'vertical';

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        minHeight: 224,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tabs
          // orientation={mobileBreakpoint ? 'horizontal' : 'vertical'}
          orientation={tabsOrientation}
          // variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          sx={{
            // borderRight: 1,
            borderColor: 'divider',
            marginTop: { xs: 0, md: 3 },
          }}
          // TabIndicatorProps={{
          //     style: { transition: 'none' },
          // }}
        >
          <Tab label="Shares" {...a11yProps(0)} />
          <Tab label="Certificates" {...a11yProps(1)} />
          <Tab label="Loans" {...a11yProps(2)} />
          <Tab label="Mortgages" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          borderRadius: 3,
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <TabPanel value={value} index={0}>
          <Box display="flex" justifyContent="center">
            <h2>Shares</h2>
          </Box>
          <SharesTable />
          <Box marginTop={6} fontSize={12}>
            <li>All dividends paid monthly</li>
            <li>Fees could reduce earnings</li>
            <li>
              APY is <i>Annual Percentage Yield</i>
            </li>
            <li>All rates subject to change without notice</li>
            <li>Minimum Balance required to open account</li>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box display="flex" justifyContent="center">
            <h2>Certificates</h2>
          </Box>
          <CertificatesTable />
          <Box marginTop={6} fontSize={12}>
            <li>All dividends paid monthly</li>
            <li>Penalty for early withdrawal</li>
            <li>Fees could reduce earnings</li>
            <li>
              APY is <i>Annual Percentage Yield</i>
            </li>
            <li>All rates subject to change without notice</li>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* Auto Loans */}
          <Box display="flex" justifyContent="center">
            <h2>New & Used Auto Loans</h2>
          </Box>
          <AutoLoansTable />
          {/* RV & Boat Loans */}
          <Box display="flex" justifyContent="center">
            <h2>RV & Boat Loans</h2>
          </Box>
          <RvBoatLoansTable />
          <Box marginTop={2} fontSize={12}>
            <li>Must be newer than 10-years old</li>
          </Box>
          {/* Consumer Loans */}
          <Box display="flex" justifyContent="center">
            <h2>Consumer Loans</h2>
          </Box>
          <ConsumerLoansTable />
          {/* Unsecured Loans */}
          <Box display="flex" justifyContent="center">
            <h2>Unsecured Loans</h2>
          </Box>
          <UnsecuredLoansTable />
          <Box marginTop={6} fontSize={12}>
            <li>$1,000 available immediately</li>
            <li>$3,500 available after 6 months</li>
            <li>
              APR is <i>Annual Percentage Rate</i>
            </li>
            <li>100% vehicle financing available OAC</li>
            <li>All rates subject to change without notice</li>
            <li>Motorcycle Loans 1% above vehicle rates</li>
            <li>Amounts available for new members with excellent credit</li>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box>
            {/* First Mortgage */}
            <Box display="flex" justifyContent="center">
              <h2>First Mortgage - Fixed Rate</h2>
            </Box>
            {/* Second Mortgage */}
            <FixedFirstMortgageTable />
            <Box display="flex" justifyContent="center">
              <h2>Second Mortgage - Fixed Rate</h2>
            </Box>
            <FixedSecondMortgageTable />
            {/* HELOC */}
            <Box display="flex" justifyContent="center">
              <h2>Home Equity Line of Credit (HELOC)</h2>
            </Box>
            <HelocTable />
            <Box marginTop={6} fontSize={12}>
              <li>
                APR is <i>Annual Percentage Rate</i>
              </li>
              <li>All rates subject to change without notice</li>
              <li>
                Appraisal, Flood Insurance, Title Insurance, and Title Fees may
                apply
              </li>
              <br />
              <b>Disclosures: Home Equity Line of Credit (HELOC)</b>
              <br />
              <i>
                *Annual Percentage Rate may vary. Maximum APR 18.00%. Limited to
                residential owner-occupied homes. APR = Annual Percentage Rate.
                Equal Housing Lender. NMLS #789558. These plans have a variable
                rate feature and the APR and minimum payment may change as a
                result. The annual percentage rate is based on the value of an
                index. The index is the Prime Rate published in the Money Rates
                column of the Wall Street Journal. When a range of rates has
                been published, the highest rate will be used. To determine the
                annual percentage rate that will apply to your account, we add a
                margin to the value of the Index. The margin you will receive is
                based on your creditworthiness and loan-to-value
              </i>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
}
