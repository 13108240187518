import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinkCard from '../../cards/LinkCard';

import BankIcon from '../../../assets/icons/banking/bank.png';
import CalculatorIcon from '../../../assets/icons/banking/calculator.png';
import PieChartIcon from '../../../assets/icons/banking/pie-chart.png';
import ShieldIcon from '../../../assets/icons/banking/shield.png';
import EnvelopeIcon from '../../../assets/icons/banking/envelope.png';
import EyeMagnifierIcon from '../../../assets/icons/banking/eye-magnifier.png';

import MenuImage from '../../../assets/stock_photos/computer.png';

const ImageItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    ...theme.typography.body1,
    // padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none', // toggle for development
    borderRadius: 0,
}));

export default function MaxWidthDialog() {
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 3, minHeight: '450px' }}>
            <Grid container spacing={0} columns={12}>
                {/* CONTAINER 1 */}
                <Grid item>
                    <Box
                        sx={{
                            boxShadow:
                                '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                        }}
                    >
                        <ImageItem
                            style={{
                                backgroundImage: `url(${MenuImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover', // contain or cover
                                backgroundPosition: 'center',
                                height: 375,
                                width: 275,
                            }}
                        >
                            <ImageItem
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'rgba(125, 125, 125, 0.5)',
                                    padding: '20px',
                                }}
                            >
                                <Typography
                                    marginTop="10px"
                                    variant="h4"
                                    color="white"
                                >
                                    <i>Hands On Banking Experience</i>
                                </Typography>
                                <Typography
                                    marginTop="3%"
                                    color="white"
                                    fontSize={14}
                                    padding={1}
                                >
                                    Tailored banking services, making you part
                                    of the Hi-Land family
                                </Typography>
                                <Button
                                    href="/resources"
                                    variant="contained"
                                    sx={{
                                        marginTop: '25%',
                                        marginLeft: '3%',
                                        minWidth: '0',
                                        backgroundColor: '#056E8B',
                                        '&:hover': {
                                            backgroundColor: '#07a8d4',
                                        },
                                    }}
                                >
                                    Get in Touch
                                </Button>
                            </ImageItem>
                        </ImageItem>
                    </Box>
                </Grid>
                {/* CONTAINER 2 - width (xs) dependent on container 1 */}
                <Grid item xs>
                    <Box
                        sx={{
                            width: '100%',
                            height: 375,
                            paddingX: 4.5,
                            paddingY: 0,
                        }}
                    >
                        <Grid
                            container
                            spacing={{ xs: 4, md: 4 }}
                            columns={{ xs: 12 }}
                        >
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Security Center"
                                    label="Ensuring your safety with robust security"
                                    icon={ShieldIcon}
                                    href="/security"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Education Center"
                                    label="Empowering with knowledge for financial success"
                                    icon={PieChartIcon}
                                    href="/education"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Financial Tools"
                                    label="Tools for smart financial decisions"
                                    icon={CalculatorIcon}
                                    href="/resources#tools"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Applications"
                                    label="Simplify paperwork, streamline financial processes"
                                    icon={EnvelopeIcon}
                                    href="/resources#apps"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="About Us"
                                    label="Our story and mission"
                                    icon={BankIcon}
                                    href="/resources#about"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LinkCard
                                    title="Helpful Links"
                                    label="Navigate with our useful links"
                                    icon={EyeMagnifierIcon}
                                    href="/resources#links"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
